<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="4" class="pr-12">
          <h1>Contact Forms Stats</h1>
        </v-col>
        <v-col cols="8" align="right"
          ><v-btn small depressed :to="{ name: 'module-contactforms' }"
            ><v-icon left>mdi-arrow-left</v-icon>Back to Forms</v-btn
          ></v-col
        >
      </v-row>

      <v-row align="center">
        <v-col cols="8">
          <v-row align="center">
            <v-col cols="3">
              <v-text-field
                type="date"
                v-model="fields.start_date"
                outlined
                hide-details
                background-color="white"
              ></v-text-field>
            </v-col>
            <v-col cols="auto">to</v-col>
            <v-col cols="3">
              <v-text-field
                type="date"
                v-model="fields.end_date"
                outlined
                hide-details
                background-color="white"
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-btn
                depressed
                color="green lighten-4 green--text"
                @click="loadFiltredStats"
              >
                Filter
              </v-btn></v-col
            >
          </v-row>
        </v-col>
        <v-col cols="4" align="right">
          <v-btn
            depressed
            class="mr-2"
            color="orange lighten-4 orange--text"
            :to="{ name: 'module-contactforms-stats-types' }"
          >
            Types
          </v-btn>

          <v-btn
            depressed
            color="blue lighten-4 blue--text"
            :to="{ name: 'module-contactforms-stats-sources' }"
          >
            Sources
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div
            v-for="(submission, index) in submissions"
            v-bind:key="submission.id"
          >
            <template>
              <v-card class="mb-6">
                <v-toolbar flat dark dense :color="appColor">
                  <v-toolbar-title>{{ index }}</v-toolbar-title>
                  <v-spacer> </v-spacer>
                </v-toolbar>
                <v-divider></v-divider>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <th>URL</th>
                        <td>#</td>
                      </tr>
                      <tr
                        v-for="(value, index2) in submission"
                        v-bind:key="value.id"
                      >
                        <td>{{ index2 }}</td>
                        <td>{{ submission[index2].length }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </template>
            <!-- <div v-else>No submissions between these dates</div> -->
          </div>
        </v-col>

        <v-col cols="6">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Types</v-toolbar-title>
              <v-spacer> </v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr v-for="(value, index2) in types" v-bind:key="value.id">
                    <template v-if="index2 != ''">
                      <td>{{ types[index2][0].type.name }}</td>
                      <td>{{ types[index2].length }}</td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Sources</v-toolbar-title>
              <v-spacer> </v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr v-for="(value, index2) in sources" v-bind:key="value.id">
                    <template v-if="index2 != ''">
                      <td>{{ sources[index2][0].source.name }}</td>
                      <td>{{ sources[index2].length }}</td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    appColor: {
      type: String,
    },
  },
  components: {},

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Contact Forms",
          disabled: false,
          exact: true,
          to: { name: "module-contactforms" },
        },
        {
          text: "Stats",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Email", value: "email" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      fields: {
        start_date: null,
        end_date: null,
      },
      deleteDialog: {
        open: false,
        recipient: {},
        loading: false,
      },
    };
  },

  computed: {
    submissions() {
      return this.$store.state.contactForms["submissionsStats"];
    },

    types() {
      return this.$store.state.contactForms["types"];
    },

    sources() {
      return this.$store.state.contactForms["sources"];
    },
  },

  methods: {
    loadFiltredStats: function () {
      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
      };

      this.$store
        .dispatch("contactForms/loadFiltredStats", payload)
        .then(() => {})
        .catch(() => {});
    },
  },
};
</script>
